import React from 'react';
import PropTypes from 'prop-types';

import CustomInput from '../../shared/components/CustomInput';
import CustomSelectWithScroll from '../../shared/components/CustomSelectWithScroll';

//import { ErrorMessage } from 'formik';
import Autocomplete from "react-autocomplete";
import { Grid, Hidden, Icon, Typography } from '@material-ui/core';
import Popover from '@material-ui/core/Popover';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import '../assets/styles/addVenue.scss';
import { useRef, useEffect, useState } from "react";
import { googleKeyData } from "../../shared/googleKeyData/actions";
import { object, func } from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from "react-router-dom";
import { form ,useField,Formik,Field, ErrorMessage, useFormikContext,useFormik } from 'formik';

let autoComplete;

let streetNumber, address_line1 = '', streetName, sublocality_level_1, cityName, postCode, stateName, city_enable = true, postcode_enable = true, addressline1_enable = true, addressline2_enable = true

const loadScript = (url, callback) => {
    let script = document.createElement("script");
    script.type = "text/javascript";
    if (script.readyState) {
        script.onreadystatechange = function () {
            if (script.readyState === "loaded" || script.readyState === "complete") {
                script.onreadystatechange = null;
                callback();
            }
        };
    } else {
        script.onload = () => callback();
    }

    script.src = url;
    document.getElementsByTagName("head")[0].appendChild(script);
};
function handleScriptLoad(updateQuery, setFieldValue, autoCompleteRef,props) {
    autoComplete = new window.google.maps.places.Autocomplete(
        autoCompleteRef.current,
        {  //fields: ["address_component"],
            types: ["address"], 
            // componentRestrictions: { country: "us" }
        }
    );
    autoComplete.setFields(["address_components", "formatted_address"]);
    autoComplete.addListener("place_changed", () =>
        handlePlaceSelect(updateQuery, setFieldValue,props)


    );
}
async function handlePlaceSelect(updateQuery, setFieldValue,props) {
    const addressObject = autoComplete.getPlace();
    const query = addressObject.address_components;
    updateQuery(addressObject)
    setFieldValue('address1', address_line1)
    setFieldValue('address2', sublocality_level_1)
    setFieldValue('cityName', cityName)
    setFieldValue('state', stateName)
    setFieldValue('zipcode', postCode)
    // handleChangeCity(cityName);
    if(cityName!=='')
    {
        props.onCitySelect(cityName)
    }

}
const styles = theme => ({
    icon: {
        marginLeft: '0px',
        paddingTop: '1px',
        color: '#b0b0b0',
        fontSize: '15px'
    },
    activeIcon: {
        marginLeft: '8px',
        paddingTop: '1px',
        color: '#1397e1',
        fontSize: '15px'
    },
    popper: {
        paddingLeft: '15px',
        paddingTop: '237px'
    },
    root: {
        boxShadow: '0px 4px 11px 4px rgba(23,0,19,0.14)',
        borderRadius: '6px'
    },
    paper: {
        width: '237px',
        height: '120px',
        fontSize: '14px',
        fontWeight: '500',
        fontStyle: 'normal',
        fontStretch: 'normal',
        lineHeight: '1.43',
        letterSpacing: 'normal',
        padding: '19px',
        color: '#1397e1',
        boxShadow: '0px 4px 11px 4px rgba(23,0,19,0.14)',
        fontFamily: 'Raleway',
        backgroundColor: '#ffffff'
    }
});

function AddVenueAddress(props) {
    const [query, setQuery] = useState("");
    const autoCompleteRef = useRef(null);
    useEffect(() => {
        props.googleKeyData().then((result) => {
            loadScript(`https://maps.googleapis.com/maps/api/js?key=${result.googleData.google_map_key}&libraries=geometry,drawing,places`,
                () => handleScriptLoad(setQuery, setFieldValue, autoCompleteRef,props)
            );
        })
    }, []);
    if (query.address_components) {
        streetNumber = ''
        streetName = ''
        cityName = ''
        postCode = ''
        sublocality_level_1 = ''
        city_enable = true
        postcode_enable = true
        addressline1_enable = true
        addressline2_enable = true
        query.address_components.forEach(component => {
            let { long_name, types } = component
            if (types.includes('street_number')) {
                streetNumber = long_name
            }
            else if (types.includes('route')) {
                streetName = long_name
            }
            else if (types.includes('administrative_area_level_1')) {
                stateName = long_name
            }
            else if (types.includes('locality')) {
                cityName = long_name
                // props.onCitySelect(cityName);

            }
            else if (types.includes('sublocality_level_1') || types.includes('neighborhood')) {
                sublocality_level_1 = long_name
            }

            else if (types.includes('postal_code')) {
                postCode = long_name
            } address_line1 = ''
            address_line1 = (streetNumber ? streetNumber : '') + ' ' + (streetName ? streetName : '')
        })
        if (cityName == '') {
            city_enable = false
        }
        if (postCode == '') {
            postcode_enable = false
        }
        if (sublocality_level_1 == '') {
            addressline2_enable = false
        }
        if (address_line1 == '') {
            addressline1_enable = false
        }
        // if(cityName !== '')
        // {
        //     props.onCitySelect(cityName);

        // }
    }
    
    const { classes } = props;
    const { setFieldValue, handleChange, values, handleBlur } = useFormikContext();

    let states = []
    props.states !== [] && props.states.map(function (data, idx) {
        states.push(data.name)
        return states;
    });
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = event => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    }

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popper' : undefined;

    return (
        <div className="add_venue_address_container">
            <Grid container className="add_venue_address">
                <Grid className="learning_hall_container" item xs={12}>
                    <h4 className="learning_hall">{props.formikProps.values.venueName}</h4>
                </Grid>
                <Grid item xs={12}>
                    <Grid container>
                        <Hidden xsDown={true}>
                            <Grid item sm={2} md={2} lg={2} xl={2}></Grid>
                        </Hidden>
                        <Grid item xs={12} sm={8} md={8} lg={8} xl={8}>
                            <Grid container className="add_venue_address_form" onMouseOut={props.handleCloseHelpIcon} >
                            <Grid container>
                                        <Grid item xs={6} sm={6} md={5} lg={5} xl={5} className="what_is_address" style={{display:'flex'}}>
                                           <div> What is the address for this venue?</div>
                                            <div aria-describedby={id} variant="contained" color="primary" onClick={handleClick}>
                                                <Icon className={props.isToggleOpen ? classes.activeIcon : classes.icon}>
                                                    help_outline
                                                </Icon>
                                            </div>
                                            <Popover
                                                id={id}
                                                open={open}
                                                anchorEl={anchorEl}
                                                onClose={handleClose}
                                                anchorOrigin={{
                                                    vertical: 'bottom',
                                                    horizontal: 'center',
                                                }}
                                                transformOrigin={{
                                                    vertical: 'top',
                                                    horizontal: 'center',
                                                }}
                                            >
                                                <Typography className={classes.paper}>This should indicate the physical location of the venue. This is the address that should go on event invitations.</Typography>
                                            </Popover>
                                        </Grid>
                                        <Grid item xs={6} sm={6} md={1} lg={1} xl={1} className="help_icon left_panel">
                                            {/* <Grid onMouseOver={props.handleHelpIconClick} onClick={props.handleHelpIconClick} onMouseOut={props.handleCloseHelpIcon}>
                                                <Icon className={props.isToggleOpen ? classes.activeIcon : classes.icon}>
                                                    help_outline
                                                </Icon>
                                            </Grid>
                                            <Popper className={classes.popper} open={open} anchorEl={anchorEl} placement="right-end">
                                                <Paper classes={{ root: classes.root }}>
                                                    <Typography className={classes.typography}>This should indicate the physical location of the venue. This is the address that should go on event invitations.</Typography>
                                                </Paper>
                                            </Popper> */}
                                            {/* <button aria-describedby={id} type="button" onClick={handleClick}>
                                                <Icon className={props.isToggleOpen ? classes.activeIcon : classes.icon}>
                                                    help_outline
                                                </Icon>
                                            </button>
                                <Popper id={id} open={open} anchorEl={anchorEl}>
                                <div className={classes.paper}>This should indicate the physical location of the venue. This is the address that should go on event invitations.</div>
                                </Popper> */}
                                            {/* <Button aria-describedby={id} variant="contained" color="primary" onClick={handleClick}>
                                                <Icon className={props.isToggleOpen ? classes.activeIcon : classes.icon}>
                                                    help_outline
                                                </Icon>
                                            </Button>
                                            <Popover
                                                id={id}
                                                open={open}
                                                anchorEl={anchorEl}
                                                onClose={handleClose}
                                                anchorOrigin={{
                                                    vertical: 'bottom',
                                                    horizontal: 'center',
                                                }}
                                                transformOrigin={{
                                                    vertical: 'top',
                                                    horizontal: 'center',
                                                }}
                                            >
                                                <Typography className={classes.paper}>This should indicate the physical location of the venue. This is the address that should go on event invitations.</Typography>
                                            </Popover> */}
                                        </Grid>
                            </Grid>
                                <Grid  item xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <Grid container>
                                        {/* <Grid item xs={7} sm={7} md={5} lg={4} xl={4} className="what_is_address">What is the address for this venue?</Grid> */}
                                        {/* <Grid item xs={5} sm={5} md={1} lg={2} xl={2} className="help_icon left_panel"> */}
                                            {/* <Grid onMouseOver={props.handleHelpIconClick} onClick={props.handleHelpIconClick} onMouseOut={props.handleCloseHelpIcon}>
                                                <Icon className={props.isToggleOpen ? classes.activeIcon : classes.icon}>
                                                    help_outline
                                                </Icon>
                                            </Grid>
                                            <Popper className={classes.popper} open={open} anchorEl={anchorEl} placement="right-end">
                                                <Paper classes={{ root: classes.root }}>
                                                    <Typography className={classes.typography}>This should indicate the physical location of the venue. This is the address that should go on event invitations.</Typography>
                                                </Paper>
                                            </Popper> */}
                                            {/* <button aria-describedby={id} type="button" onClick={handleClick}>
                                                <Icon className={props.isToggleOpen ? classes.activeIcon : classes.icon}>
                                                    help_outline
                                                </Icon>
                                            </button>
                                <Popper id={id} open={open} anchorEl={anchorEl}>
                                <div className={classes.paper}>This should indicate the physical location of the venue. This is the address that should go on event invitations.</div>
                                </Popper> */}
                                            {/* <Button aria-describedby={id} variant="contained" color="primary" onClick={handleClick}>
                                                <Icon className={props.isToggleOpen ? classes.activeIcon : classes.icon}>
                                                    help_outline
                                                </Icon>
                                            </Button>
                                            <Popover
                                                id={id}
                                                open={open}
                                                anchorEl={anchorEl}
                                                onClose={handleClose}
                                                anchorOrigin={{
                                                    vertical: 'bottom',
                                                    horizontal: 'center',
                                                }}
                                                transformOrigin={{
                                                    vertical: 'top',
                                                    horizontal: 'center',
                                                }}
                                            >
                                                <Typography className={classes.paper}>This should indicate the physical location of the venue. This is the address that should go on event invitations.</Typography>
                                            </Popover>
                                        </Grid> */}
                                        <Grid className="left_panel what_is_address-top" item xs={12} sm={12} md={6} lg={6} xl={6}>
                                            <div className="address_1">
                                            <div className="field_div custom_input search_container with-lable-search">
                                            <div 
                                                style={{
                                                width: '60px !important',
                                                height: '46px !important',
                                                position: 'absolute',
                                                top: '23px'}}></div>
                                                <label className='emailLabel sm-pd-top'>Address</label>
                                                <input type="text" style={{ display: 'none' }} autoComplete="off" />
                                                    <input
                                                        autoComplete='off'
                                                        ref={autoCompleteRef}
                                                        className="emailField" align="center" type="text" name="location" onChange={e => {
                                                            setQuery(e.target.value)
                                                        }
                                                        }
                                                        placeholder="Type here to search..."

                                                    />
                                                </div>
                                            </div>
                                        </Grid>
                                    </Grid>
                                   
                                </Grid>
                                {/* <Grid className="left_panel" item xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <div className="address_1">
                                        <div class="custom_input">
                                            <label className="emailLabel">
                                                Address
                                            </label>
                                            <input
                                                ref={autoCompleteRef}
                                                className="emailField" align="center" type="text" name="location" onChange={e => {
                                                    setQuery(e.target.value)
                                                }
                                                }
                                                placeholder="Address"

                                            />
                                        </div>
                                    </div>
                                </Grid> */}
                                <Grid className="left_panel" item xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <div className="address_1">
                                        <CustomInput label="Address #1" type="text" name="address1" placeholder="123 Street Ave."  disabled={addressline1_enable}/>
                                    </div>
                                </Grid>
                                <Grid className="left_panel" item xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <div className="address_2">
                                        <CustomInput label="Address #2" type="text" name="address2" placeholder="Address #2" disabled={addressline2_enable}/>
                                    </div>

                                </Grid>
                                <Grid className="left_panel" item xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <div className="city_name">
                                        <label>
                                            City
                                        </label>
                                        {/* <CustomInput label="City" type="text" name="cityName" placeholder="City Name" /> */}
                                        <Autocomplete
                                            fields={{ groupBy: 'value', value: 'menu' }}
                                        value={ props.formikProps.values.cityName }
                                        inputProps={{id: 'city',placeholder:'City Name'}}
                                            wrapperStyle={{ position: 'relative' }}
                                        items={ props.citySuggestionList }
                                        getItemValue={ item => item.key }
                                            shouldItemRender={(item, value) => item.key.toLowerCase().indexOf(value.toLowerCase()) > -1}
                                            onChange={(e) => {
                                                props.onCitySelect(e.target.value);
                                                props.formikProps.setFieldValue('cityName', e.target.value)
                                            }}
                                            onSelect={(val) => {
                                                props.onCitySelect(val);
                                            props.formikProps.setFieldValue('cityName',val)}}
                                        renderMenu={ children => (
                                        <div className = "suggestionDiv">
                                            { children }
                                                </div>
                                            )}
                                        renderItem={ (item, isHighlighted) => (
                                                <div
                                                    className={`item ${isHighlighted ? 'item-highlighted' : ''}`}
                                        key={ item.id } >
                                        { item.key }
                                                </div>
                                            )}
                                        />
                                        {!props.isCitySelected && props.formikProps.values.cityName.length > 0 ?
                                            <div className="error">*Select a valid  city</div>
                                            :
                                            <div className="error"><ErrorMessage name='cityName' /></div>
                                        }
                                    </div>




                                </Grid>
                                <Grid  className="left_panel" item xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <div className="state" style={{position: 'relative'}}>
                                        <CustomSelectWithScroll label="State/Province" showLabel placeholder="State" states={states} name="state" value={props.formikProps.values.state} handleChange={props.formikProps.handleChange} handleBlur={props.formikProps.handleBlur} disabled={true}/>
                                    </div>
                                </Grid>
                                <Grid className="left_panel" item xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <div className="zipcode">
                                        <CustomInput label="Zip Code" type="zip" name="zipcode" placeholder="12345" upperCaseInput disabled={postcode_enable}/>
                                    </div>
                                </Grid>
                                <Grid  className="left_panel" item xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <div className="city_name">
                                        <label>
                                            Closest Airport
                                        </label>
                                        <Autocomplete
                                            fields={{ groupBy: 'value', value: 'menu' }}
                                        value={ props.formikProps.values.airport }
                                        inputProps={{id: 'airport',placeholder:'Airport closest to venue (including regional)'}}
                                            wrapperStyle={{ position: 'relative' }}
                                        items={ props.airportSuggestionList }
                                        getItemValue={ item => item.key }
                                            shouldItemRender={(item, value) => item.key.toLowerCase().indexOf(value.toLowerCase()) > -1}
                                            onChange={(e) => {
                                                props.onAirportSelect(e.target.value);
                                                props.formikProps.setFieldValue('airport', e.target.value)
                                            }}
                                            onSelect={(val) => {
                                                props.onAirportSelect(val);
                                            props.formikProps.setFieldValue('airport',val)}}
                                        renderMenu={ children => (
                                        <div className = "suggestionDiv">
                                            { children }
                                                </div>
                                            )}
                                        renderItem={ (item, isHighlighted) => (
                                                <div
                                                    className={`item ${isHighlighted ? 'item-highlighted' : ''}`}
                                        key={ item.id } >
                                        { item.key }
                                                </div>
                                            )}
                                        />
                                        {!props.isAirportSelected && props.formikProps.values.airport.length > 0 ?
                                            <div className="error">*Select a valid airport</div>
                                            :
                                            <div className="error"><ErrorMessage name='airport' /></div>
                                        }
                                    </div>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Hidden xsDown={true}>
                            <Grid item sm={2} md={2} lg={2} xl={2}></Grid>
                        </Hidden>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
}

// AddVenueAddress.propTypes = {
//     classes: PropTypes.object.isRequired,
// };

// const enhance = withStyles(styles)

// export default enhance(AddVenueAddress);
const mapStateToProps = state => {
    return {
        //states: state.data.auth.states,
        googleKeyDetails: state.data.googleKeyData.googleKey,

    };
};

AddVenueAddress.propTypes = {
    classes: PropTypes.object.isRequired,
    googleKeyData: func.isRequired,
};


const enhance = compose(
    withStyles(styles),
    connect(
        mapStateToProps,
        null),
    connect(mapStateToProps, dispatch =>
        bindActionCreators(
            {
                googleKeyData
            },
            dispatch
        )
    )
)
export default enhance(withRouter(AddVenueAddress));