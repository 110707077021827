import React from 'react';

import { Field } from 'formik';

import './assets/style/CustomCheckBox.scss';

function SyncCheckBox(props) {
    const {id}=props;
    return (
        <div style={{marginTop:props.marginTop?props.marginTop:null}} className="custom_checkbox">
            <label className={(props.values.length > 1) ? 'blue_text_container' : 'container' }>{props.label}
          {props.onChange?
          <Field 
          type="checkbox"
              name={ props.name} 
              checked={ props.values.length > 1? true:false} 
              onChange={(e) => {
                 props.onChange(e ,props.type,props.index,id?id:"");
                 props.handleChange(e);

                }}/>
                :
                      
                <Field 
                type="checkbox"
                    name={ props.name } 
                    checked={ props.values.length > 1? true:false } 
              />
        }
      
                <span className="checkmark"></span>
            </label>
        </div>
    );
}


export default SyncCheckBox;
