//import React from 'react';
import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from "react-router-dom";

import CustomizedButton from '../../../shared/components/customizedButton';
import CustomSelectWithScroll from '../../../shared/components/CustomSelectWithScroll';
// import CustomisedRadio from '../../../shared/components/CustomisedRadio';
import CustomizedCheckBox from '../../../shared/components/CustomizedCheckbox';
import {
  Grid, TextField
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { form ,useField,Formik,Field, ErrorMessage, useFormikContext,useFormik } from 'formik';
import PropTypes from 'prop-types';
import '../../assets/styles/auth.scss';
import { useRef, useEffect,useState} from "react";
//import Autocomplete from 'react-google-autocomplete';
import { getKeys} from '../../../data/master/actions';
import { googleKeyData } from "shared/googleKeyData/actions";
import { object, func } from "prop-types";
import { bindActionCreators} from "redux";
   
let autoComplete;

let streetNumber,address_line1='' ,streetName ,sublocality_level_1, cityName ,postCode ,stateName,city_enable=true,postcode_enable=true,addressline1_enable=true,addressline2_enable=true

const loadScript = (url, callback) => {
  let script = document.createElement("script");
  script.type = "text/javascript";
  if (script.readyState) {
    script.onreadystatechange = function() {
      if (script.readyState === "loaded" || script.readyState === "complete") {
        script.onreadystatechange = null;
        callback();
      }
    };
  } else {
    script.onload = () => callback();
  }

  script.src = url;
  document.getElementsByTagName("head")[0].appendChild(script);
};
function handleScriptLoad(updateQuery, setFieldValue, autoCompleteRef) {
  autoComplete = new window.google.maps.places.Autocomplete(
    autoCompleteRef.current,
    {  //fields: ["address_component"],
      types: ["address"],
      //  componentRestrictions: { country: "us" } 
      }
  );
  autoComplete.setFields(["address_components", "formatted_address"]);
  autoComplete.addListener("place_changed", () =>
    handlePlaceSelect(updateQuery,setFieldValue)
    
    
  );
}
async function handlePlaceSelect(updateQuery,setFieldValue) {
  const addressObject = autoComplete.getPlace();
  const query = addressObject.address_components;
  updateQuery(addressObject)
  setFieldValue('line_1',address_line1)
  setFieldValue('line_2',sublocality_level_1)
  setFieldValue('city',cityName)
  setFieldValue('state',stateName)
  setFieldValue('zipcode',postCode)
  //setFieldValue('job_title','') 
  handleChangeCompanyName(setFieldValue);
}

const styles = theme => ({
  signupForm: {
    margin: '80px auto 30px',
    width: '430px',
    padding: '0 25px',
    borderRadius: '6px',
    backgroundColor: '#ffffff',
    boxShadow: '0 2px 2px 0 rgba(0, 0, 0, 0.24), 0 0 2px 0 rgba(0, 0, 0, 0.12)',
    borderStyle: 'solid',
    borderWidth: '1px',
    borderImageSource: 'linear-gradient(to bottom, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.1) 5%, rgba(255, 255, 255, 0) 20%, rgba(255, 255, 255, 0))'
  },
  formFields: {
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '89%',
    marginTop: '25px'
  },
  signupDiv: {
    display: 'table',
    margin: '0 auto'
  },
  signupButton: {
    fontSize: '15px',
    width: '130px',
    height: '36px',
    borderRadius: '6px',
    backgroundImage: 'linear-gradient(#f1435e, #ee2738)',
    color: '#ffffff',
    fontWeight: 500,
    border: 'none',
    marginBottom: '30px',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: '34px',
    letterSpacing: 'normal',
    boxShadow: '0 2px 2px 0 rgba(0, 0, 0, 0.24), 0 0 2px 0 rgba(0, 0, 0, 0.12)',
    marginTop: '30px'
  },
  greySignupButton: {
    fontSize: '13px',
    width: '130px',
    height: '36px',
    borderRadius: '6px',
    color: '#ffffff',
    fontWeight: 500,
    border: 'none',
    marginBottom: '30px',
    backgroundColor: '#9aa7af',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    marginTop: '30px'
  },
  already: {
    fontSize: '12px',
    color: '#ffffff',
    fontWeight: 500,
    marginTop: '20px',
    paddingBottom: '20px',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 1.33,
    letterSpacing: 'normal'
  },
  signinLink: {
    color: '#ffffff',
    "&:hover": {
      color: '#ffffff',
      textDecoration: 'underline',
    }
  },
  textField: {
    width: '100%',
    marginBottom: '15px'
  },
  didit: {
    marginBottom: '-30px',
    paddingTop: '24px'
  },
  noCreditCard: {
    fontSize: '12px',
    margin: '-18px 0 8px 0'
  }
});

function handleChangeCompanyName(setFieldValue) {
  if (localStorage.getItem('user_type') === '0') {
    setFieldValue('job_title', 'Not')
  }
}
function handleChangeJobTitle(setFieldValue) {
  // setFieldValue('company_name','Not')
}

// function handleRadioClick(event, setFieldValue){
//   setFieldValue('tier', event.target.value)
//   localStorage.removeItem('tier')
// }

function openVenuePlan(props) {
  localStorage.setItem('disable_plan_selection', true)
}



function CompanyInfo(props) {
  const [query, setQuery] = useState("");
  const autoCompleteRef = useRef(null);
  useEffect(() => {
    props.googleKeyData().then((result) => {
            loadScript(`https://maps.googleapis.com/maps/api/js?key=${result.googleData.google_map_key}&libraries=geometry,drawing,places`,
      () => handleScriptLoad(setQuery,setFieldValue, autoCompleteRef)                                     
      );
  })
    }, []);
 if(query.address_components)
 
 {
  streetNumber=''
  streetName=''
  cityName=''
  postCode=''
  sublocality_level_1=''
  city_enable=true
  postcode_enable=true
  addressline1_enable=true
  addressline2_enable=true
  query.address_components.forEach(component => {
    let { long_name, types } = component
    if (types.includes('street_number')) {
      streetNumber = long_name
    } 
    else if (types.includes('route')) {
      streetName = long_name
    } 
    else if (types.includes('administrative_area_level_1')) {
      stateName= long_name
    }
    else if (types.includes('locality')) {
      cityName = long_name
    } 
    else if (types.includes('sublocality_level_1')||types.includes('neighborhood') ){
      sublocality_level_1 = long_name
    } 
    
    else if (types.includes('postal_code')) {
      postCode = long_name
    }  address_line1=''
    address_line1=(streetNumber?streetNumber:'')+ ' '+(streetName?streetName:'')
 })
 if(cityName=='')
 {
 city_enable=false
 }
 if(postCode=='')
 {
 postcode_enable=false
 }
 if(sublocality_level_1=='')
 {
  addressline2_enable=false
 }
 if(address_line1=='')
 {
  addressline1_enable=false
 }
}
  
  let states=[]
  let key=[]
  // const tier = localStorage.getItem('tier')
  const { classes } = props;
  const { setFieldValue, handleChange, values, handleBlur } = useFormikContext();
  props.states !== [] && props.states.map(function (data, idx) {

    states.push(data.name)
    key.push(idx)
    return key;
  });

  return (

    <div className="signup company-info-form">
  
    <Grid className="signup__inner" container justify="center">
        <Grid item xs={10} md={4} lg={4} sm={6}>
          
          <div className={`${classes.signupForm} company-info`}>
            <div className={classes.formFields}>
              {localStorage.getItem('user_type') === '0' &&
                <Grid>
                  <div className="hintText">
                    <p>Last page! (Step 3 of 3)</p>
                  </div>
                </Grid>
              }
              <Grid container justify="center">
                {localStorage.getItem('user_type') === '0' &&
                  <label className="emailLabel">
                    COMPANY NAME
                  </label>
                }
                {localStorage.getItem('user_type') === '0' &&
                  <Field className="emailField" align="center" type="text" name="company_name" placeholder="Company Name (optional)" onChange={e => {
                    handleChangeCompanyName(setFieldValue)
                    handleChange(e)
                  }} />
                }
                {localStorage.getItem('user_type') === '0' &&
                  <div className="error"><ErrorMessage name="company_name" /></div>
                }
                {localStorage.getItem('user_type') === '0' &&
                  <label className="emailLabel pd-top-title">
                    JOB TITLE
                  </label>
                }
                {localStorage.getItem('user_type') === '0' &&
                  <Field className="emailField" align="center" type="text" name="job_title_planner" placeholder="Job Title (optional)"
                    onChange={e => {
                      handleChangeJobTitle(setFieldValue)
                      handleChange(e)
                    }}
                  />
                }
                  <label className="emailLabel sm-pd-top">
                  Search Location
                </label>
                <input type="text" style={{ display: 'none' }} autoComplete="off" />
                <input
                autoComplete='off'
                ref={autoCompleteRef}
                className="emailField" align="center" type="text" name="location"  onChange={e=>{setQuery(e.target.value) 
                  }
                }
                placeholder="Type here to search..."
                  
                 />
         
                <label className="emailLabel sm-pd-top">
                  ADDRESS LINE 1
                </label>
                <Field className="emailField" align="center" type="text" name="line_1" placeholder="Address Line1" readOnly={addressline1_enable}/>
                <div className="error"><ErrorMessage name="line_1" /></div>
                <label className="emailLabel sm-pd-top">
                  ADDRESS LINE 2
                </label>
                <Field className="emailField" align="center" type="text" name="line_2" placeholder="Address Line2" readOnly={addressline2_enable} />
                <div className="error"><ErrorMessage name="line_2" /></div>
                <label className="emailLabel sm-pd-top">
                  CITY
                </label>
                <Field className="emailField" align="center" type="text" name="city" placeholder="City" readOnly={city_enable}/>
                <div className="error"><ErrorMessage name="city" /></div>
                <label className="stateLabel">
                  STATE / PROVINCE

                </label>
                <div className="state_div">
                  <Field placeholder="Select" className="emailField"  states={states} name="state" value={values.state} handleChange={handleChange} handleBlur={handleBlur} registrationSelect label="STATE / PROVINCE" readOnly={true}/>
                </div>
                <label className="emailLabel">
                  ZIP CODE
                </label>
                <Field className="emailField zip" align="center" type="text" name="zipcode" placeholder="Zip Code"  readOnly={postcode_enable}/>
                <div className="error"><ErrorMessage name="zipcode" /></div>
                {localStorage.getItem('user_type') === '1' &&
                  <label className="emailLabel pd-top-title">
                    JOB TITLE
                  </label>
                }
                {localStorage.getItem('user_type') === '1' &&
                  <Field className="emailField" align="center" type="text" name="job_title" placeholder="Job Title"
                    onChange={e => {
                      handleChangeJobTitle(setFieldValue)
                      handleChange(e)
                    }}
                  />
                }
                {localStorage.getItem('user_type') === '1' &&
                  <div className="error"><ErrorMessage name="job_title" /></div>
                }
                <Grid className="agree_check">
                  <CustomizedCheckBox
                    name='agree'
                    value={values.agree}
                    label={<p className="agree-text-size">I agree to the FIRST-HOLD
                      <Link className="terms" to='/terms' onClick={(e) => { openVenuePlan(props) }} target="_blank"> Terms and Conditions</Link></p>}
                  />
                  <div className="error agree_error"><ErrorMessage name="agree" /></div>
                </Grid>
                {localStorage.getItem('user_type') === '0' && <Grid className="agree_check">
                  <CustomizedCheckBox
                    name='newsletter'
                    value={values.newsletter}
                    label={<p className="agree-text-size">Receive the occasional email about new venues and special discounts.
                    </p>}
                  />
                </Grid>}
                {localStorage.getItem('user_type') === '1' && <Grid className="agree_check">
                  <CustomizedCheckBox
                    name='newsletter'
                    value={values.newsletter}
                    label={<p className="agree-text-size">Receive emails about features and benefits.
                    </p>}
                  />
                </Grid>}
                {localStorage.getItem('user_type') === '1' &&
                  <Grid item className="select_tier">
                    {/* <label className="emailLabel pd-top-title">
                      SELECT PLAN FOR TRIAL
                    </label>
                    <CustomisedRadio
                      name="tier"
                      value="1"
                      handleChange={handleChange}
                      onChange={handleRadioClick}
                      setFieldValue={setFieldValue}
                      radioSelectedValue={tier ? tier : values.tier}
                      class="radioButton"
                      label="Tier One"
                    />
                    <CustomisedRadio
                      name="tier"
                      value="2"
                      handleChange={handleChange}
                      onChange={handleRadioClick}
                      setFieldValue={setFieldValue}
                      radioSelectedValue={tier ? tier : values.tier}
                      class="radioButton"
                      label="Tier Two"
                    />
                    <CustomisedRadio
                      name="tier"
                      value="3"
                      handleChange={handleChange}
                      onChange={handleRadioClick}
                      setFieldValue={setFieldValue}
                      radioSelectedValue={tier ? tier : values.tier}
                      class="radioButton"
                      label="Tier Three"
                    /> */}

                    {/* <Grid className="special_offer_check">
                      <CustomizedCheckBox 
                        name='special_offer' 
                        value={values.special_offer} 
                        label={<p className="agree-text-size">Yes, I would like to sign-up for your <span className="red">6 months FREE</span> special offer.  No credit card required.</p>} 
                      />
                    </Grid> */}
                    <Grid container justify="center">
                      <Grid>
                        <Link className="plan_link" to='/venuePlanList' onClick={(e) => { openVenuePlan(props) }} target="_blank">View Plan Details</Link>
                      </Grid>
                    </Grid>
                  </Grid>
                }
              </Grid>
              {localStorage.getItem('user_type') === '0' &&
                <div align="center" className={classes.didit}>
                  <p>You did it!</p>
                </div>
              }
              <div className="signupDiv">
                <CustomizedButton label={localStorage.getItem('user_type') === '1' ? 'Sign Up' : 'Sign Up'} type="submit" />
              </div>

              {localStorage.getItem('user_type') === '1' &&
                <div align="center" className={classes.noCreditCard}>
                  {/* <p>No credit card required.</p> */}
                </div>
              }
              
            </div>
            
          </div>
          <div className={classes.already} align="center">Already have a FIRST-HOLD Account?  <Link className="signinLink" to='/login'>Sign In</Link>.</div>
         
        </Grid>
        
      </Grid>
      
    
    </div>
  
    
  );
}




const mapStateToProps = state => {
  return {
    states: state.data.auth.states,
    googleKeyDetails: state.data.googleKeyData.googleKey,
    
  };
};

CompanyInfo.propTypes = {
  classes: PropTypes.object.isRequired,
  googleKeyData: func.isRequired,
};


const enhance = compose(
  withStyles(styles),
  connect(
    mapStateToProps,
  null),
  connect(mapStateToProps, dispatch =>
    bindActionCreators(
      {
      googleKeyData
      },
      dispatch
    )
  )
)
export default enhance(withRouter(CompanyInfo));
