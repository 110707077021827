import React from 'react';
import moment from 'moment';
import {object} from 'prop-types';

import {withStyles} from '@material-ui/core/styles';
import {Grid, Button} from '@material-ui/core';
import {Link} from "react-router-dom";
import '../assets/styles/footer.scss';
import Subscription from '../../shared/components/Subscription.js';
const styles = theme => ({
    root: {
        width: '176px',
        height: '49px',
        boxShadow: '0 2px 2px 0 rgba(0, 0, 0, 0.24), 0 0 2px 0 rgba(0, 0, 0, 0.12)',
        borderRadius: '6px',
        background: 'linear-gradient(to bottom, #f1435e, #ee2738)',
        fontSize: '13px',
        fontWeight: '500',
        marginBottom: '16px',
        '&$disabled': {
            background: '#9aa7af',
            color: 'white',
            boxShadow: 'none'
        }
    },
    landingFooter: {
        backgroundColor:"#1a181e"
    },
    chromeIconImage: {
        height: '24px',
        width: '24px',
        marginLeft: '8px'
    },
    chromeExtensionButton: {
        width: '216px',
        height: '36px',
        fontSize: '14px',
        fontWeight: '500',
        letterSpacing: '0.5px',
        textAlign: 'center',
        color: '#ffffff',
        textTransform: 'uppercase',
        backgroundColor: '#ee2738',
        '&:hover': {
            background: "#d80e2a"
        }
    }
});

function Footer(props) {
    const {classes} = props;
    const currentYear = moment().format('YYYY')
    const nextYear = parseInt(moment().format('YY')) + 1
    let widgetPages = 0;
    if (localStorage.getItem('widget')) {
        widgetPages = localStorage.getItem('widget');
    }
    const redirect = () => {
        window.open("/schedule");
    }  

    return (
        <div>
            {widgetPages == 0
                ? <div className="landing-footer">
                        <Grid container md={12} sm={12}>
                            <Grid item md={4} sm={12} xs={12}>
                                <Grid container className="logo-section">
                                    <img
                                        className="logo"
                                        src={require('../../shared/components/assets/images/LogoWithText1.png')}
                                        alt="First-Hold"/>
                                </Grid>
                            </Grid>
                            <Grid item md={2} sm={12} xs={12}>
                                <Grid container className="">
                                </Grid>
                            </Grid>
                            <Grid item md={6} sm={12} xl={6} lg={6} xs={12}>
                                <Grid container justify="right"  >
                                    <Grid item className="footer-top-links">
                                        <Link className="footer_links" to="/planners">
                                            For Planners
                                        </Link>
                                        <Link className="footer_links" to="/">
                                            For Venues
                                        </Link>
                                        <Link className="footer_links" to="/venuePlanList">
                                            Pricing
                                        </Link>                                        
                                        <Link className="footer_links" to="/login">
                                            Login
                                        </Link>
                                        <Button className="footer-btn"
                                                variant="contained"
                                                onClick={() => redirect()}
                                                color="secondary"
                                                classes={{
                                                root: classes.chromeExtensionButton
                                            }}>
                                            Book a Demo
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item md={1} sm={12} xs={12}>
                                <Grid container className="">
                                </Grid>
                            </Grid>
                            <Grid item md={4} sm={6} xl={4} lg={4} xs={12} className="section-one" >
                                <Grid item sm={5} xs={6} container style={{ display: 'inline-block', float: 'left'}}>
                                    <Grid item xs={12}>
                                        <Grid container className="links_section">
                                            <Link to="/aboutUS" className="footer_links">About Us</Link>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid container className="links_section">
                                            <Link to="/explore" className="footer_links">Explore</Link>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid container className="links_section">
                                            <Link to="./terms" className="footer_links">Terms & Conditions</Link>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid container className="links_section">
                                            <Link to="/contactUs" className="footer_links">Contact Us</Link>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid container className="links_section">
                                            <a
                                                href="https://chrome.google.com/webstore/detail/first-hold/gbgllbpnnhldcnpkbfmiiikkbggjknke"
                                                target="_blank"
                                                className="footer_links">Extension</a>
                                            
                                        </Grid>
                                    </Grid>
                                    {localStorage.getItem('fhtoken')!=''&&localStorage.getItem('type')==='1' &&
                                    <Grid item xs={12}>
                                        <Grid container className="links_section">
                                            <a
                                                href="membership"
                                                className="footer_links">Membership</a>
                                            
                                        </Grid>
                                    </Grid>
                                     }
                                </Grid>
                                <Grid item sm={5} xs={6} container>

                                    <Grid item xs={12}>
                                        <Grid container className="links_section">
                                            <a
                                                href="/help"
                                                target="_blank"
                                                className="footer_links">Help Center</a>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid container className="links_section">
                                            <a
                                                href="/help/articles?keyword=planners"
                                                target="_blank"
                                                className="footer_links">Help for Planners</a>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid container className="links_section">
                                            <a
                                                href="/help/articles?keyword=venues"
                                                target="_blank"
                                                className="footer_links">Help for Venues</a>
                                        </Grid>
                                    </Grid> 
                                    <Grid item xs={12}>
                                        <Grid container className="links_section">
                                        <Link to="/blog" className="footer_links">Blog</Link>
                                        </Grid>
                                    </Grid>  
                                    <Grid item xs={12}>
                                        <Grid container className="links_section">
                                        <Link to="/press" className="footer_links">Press</Link>
                                        </Grid>
                                    </Grid>                                   
                                </Grid>
                            </Grid>
                            <Grid item md={3} sm={6} xl={3} lg={3} xs={12}>
                                <div className="socail-link-section">
                                    <div className ="social-block"> 
                                        <span>Let's get social</span>
                                    </div>
                                    <ul className="social-link">
                                        <li>
                                            <a href="https://www.facebook.com/firsthold1" target="_blank">
                                                <img className="icons" src={require('../assets/images/footer/facebook.svg')} alt="First-Hold"/>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://www.instagram.com/firsthold" target="_blank">
                                                <img className="icons" src={require('../assets/images/footer/instagram.svg')} alt="First-Hold"/>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://www.youtube.com/channel/UC544e1CPvOJnTIoezQE3tQg" target="_blank">
                                                <img className="icons" src={require('../assets/images/footer/youtube.svg')} alt="First-Hold"/>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://www.linkedin.com/company/first-hold" target="_blank">
                                                <img className="icons" src={require('../assets/images/footer/linkedin.svg')} alt="First-Hold"/>
                                            </a>
                                        </li>
                                    </ul>
                                    <div className="send-mail">                                       
                                        <Subscription></Subscription>
                                    </div>
                                </div>
                            </Grid>
                            <Grid item md={3} sm={12} xl={3} lg={3} xs={12} className="footer-text">
                                <h2>FIRST-HOLD , Inc.​</h2>
                                <p className="desktop-view">
                                    We believe it should be easy for<br/>​ 
                                    unique and interesting venues to<br/>​  
                                    connect with planners who are ready​<br/>​ 
                                    to learn more about their space.
                                </p>
                                <p className="mobile-view">
                                    We believe it should be easy for<br/>​ 
                                    unique and interesting venues to<br/>​  
                                    connect with planners who are ready​<br/>​ 
                                    to learn more about their space.
                                </p>
                            </Grid>
                            <Grid item md={1} sm={12} xs={12}>
                                <Grid container className="">
                                </Grid>
                            </Grid>
                            <Grid item md={12} sm={12} xl={12} lg={12} xs={12}>
                                <Grid className="copyright" container justify="center">
                                &#174;{currentYear} &#8211; FIRST-HOLD, Inc. | All Rights Reserved.
                                </Grid>
                            </Grid>
                        </Grid>
                    </div>
                : null}
               
        </div>
    );
}

Footer.propTypes = {
    classes: object.isRequired
};

export default withStyles(styles)(Footer);
