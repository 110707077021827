import React from 'react';
import { object } from 'prop-types';

import { ErrorMessage } from 'formik';
import { withStyles } from '@material-ui/core/styles';
import { Select, MenuItem, Input } from '@material-ui/core';

// import './assets/style/CustomSelectWithScroll.scss';


const styles = theme => ({
    root: {
        fontSize: '15px',
        lineHeight: '1.07',
        textAlign: 'left',
        color: '#202020',
        textTransform: 'capitalize',
        '&:hover': {
            background: "#f7f7f7"
        },
        '&:focused': {
            background: "#f7f7f7"
        }
    },
    selectContainer: {
        borderRadius: '6px',
        border: 'solid 1px #e1e1e1',
        background: '#f7f7f7 !important',
        height: '36px',
        width: '100%',
        padding: '0px 4px',
        '&:hover': {
            backgroundColor: "#f7f7f7 !important"
        },
        '&:focused': {
            backgroundColor: "#f7f7f7 !important"
        }
    },
    select: {
        '&:hover': {
            backgroundColor: "#f7f7f7 !important"
        },
        '&:focus': {
            backgroundColor: "#f7f7f7 !important"
        }
    },
    placeholder: {
        fontFamily: 'Raleway',
        fontSize: '15px',
        fontWeight: 'normal',
        fontStyle: 'normal',
        fontStretch: 'normal',
        lineHeight: '1.07',
        letterSpacing: 'normal',
        textAlign: 'left',
        color: '#6c7173'
    },
    options: {
        fontFamily: 'Raleway',
        fontSize: '14px',
        fontWeight: 'normal',
        fontStyle: 'normal',
        fontStretch: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        textAlign: 'left',
        color: '#ee2738',
        borderBottom: '1px solid #f7f7f7',
        backgroundColor: '#ffffff',
    },
    registrationStateSelect: {
        width: '100%',
        height: '36px',
        border: 'solid 1px #e1e1e1',
        backgroundColor: '#f7f7f7 !important',
        fontWeight: 'normal',
        fontStyle: 'normal',
        fontStretch: 'normal',
        lineHeight: '1.5',
        letterSpacing: 'normal',
        color: 'rgba(0, 0, 0, 0.87)',
        fontFamily: 'Roboto',
        fontSize: '14px',
        borderRadius: '6px',
        // paddingLeft: '15px',
        paddingRight: '15px',
        '&:hover':{
          border:'1px solid #bbbbbb'
        },
        '&:focus':{
            border:'1px solid #bbbbbb'
          },
        '&:active':{
            border:'1px solid #bbbbbb'
        }
    }
});

function CustomSelectWithScroll(props) {
    const loadMoreItems = (e) => {
        const bottom = e.target.scrollHeight === e.target.scrollTop + e.target.clientHeight;
        if(bottom && props.updatePage ){
        props.updatePage()
        }
        };
    const { classes } = props;
    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
            onScroll:loadMoreItems,
        },
    };
    return (
        <div>
            <div className="custom_select_with_scroll">
                {props.showLabel &&
                    <div className="label_container">
                        <label>
                            {props.label}
                        </label>
                    </div>
                }
                <Select
                    className={props.registrationSelect ? classes.registrationStateSelect : classes.selectContainer}
                    classes={{ root: classes.root, select: classes.select }}
                    displayEmpty
                    onChange={props.handleChange}
                    // onChange={(e)=>{props.handleChange(e.target.value)
                    //     props.setFieldValue(`${props.name}`,e.target.value)}}
                    onBlur={props.handleBlur}
                    value={props.value}
                    name={props.name}       
                    input={<Input id="select-multiple-placeholder" />}
                    MenuProps={MenuProps}
                    disabled={props.disabled?props.disabled:false}
                >
                    <MenuItem disabled={false} value="">
                        <em className={classes.placeholder}>{props.placeholder}</em>
                    </MenuItem>
                    {typeof props.options=='array' && props.options && props.options.map(option => (    /* eslint-disable-line */
                        <MenuItem classes={{ root: classes.options }} key={option} value={option}>
                            {option}
                        </MenuItem>
                    ))}
                    {props.venueTypes && props.venueTypeValue && props.venueTypes.map((venue, i) =>(
                        <MenuItem classes={{ root: classes.options }} key={i} value={props.venueTypeValue[i]}>
                            {venue}
                        </MenuItem>
                    ))}
                    {props.states && props.states.map(state => (
                        <MenuItem classes={{ root: classes.options }} key={state} value={state}>
                            {state}
                        </MenuItem>
                    ))}
                    {typeof props.options === 'object' && props.options && props.options.map((option,idx) => (
                        <MenuItem classes={{ root: classes.options }} key={idx} value={option.id}>
                            {option.name}
                        </MenuItem>
                    ))}
                </Select>
            </div>
            <div className="error"><ErrorMessage name={props.name} /></div>
        </div>
    );
}

CustomSelectWithScroll.propTypes = {
    classes: object.isRequired,
};

export default withStyles(styles)(CustomSelectWithScroll);
