import React from 'react';
import '../assets/styles/landing-page.scss';
import { Grid } from '@material-ui/core';
import { Link } from "react-router-dom";
import FeaturedVenues from 'landingPage/components/featuredVenues';
import { homepage } from "shared/homepage/actions";
import { bindActionCreators, compose } from "redux";
import { object, func } from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Footer from 'explore-landing-page/component/footer';
import { Helmet } from "react-helmet";
import Footers from '../../landingPage/components/footer';

const logo = require("landingPage/assets/images/default_venue.png");

function eventspace(props) {
    const [venueHomePage, setvenueHomePage] = React.useState();
    React.useEffect(() => {
        props.homepage().then((result) => {
            setvenueHomePage(result.homeVenue.data.featuredVenues)
        })
    }, []);
    let featureVenueData = [];
    venueHomePage && venueHomePage.map(function (data, idx) {
        let obj = {};
        let type = '';
        if (data.type === '1') {
            type = "Conferences";
        }
        else if (data.type === '2') {
            type = "Weddings";
        }
        else if (data.type === '3') {
            type = "Workshops";
        }
        else if (data.type === '4') {
            type = "Trade Shows";
        }
        else if (data.type === '5') {
            type = "Corporate Parties";
        }
        else if (data.type === '6') {
            type = "Fundraisers";
        }
        let stateAbbr = '';
        if (data.address.state_abbr !== null) {
            stateAbbr = data.address.state_abbr;
        }
        obj.checkTag = "checkTag";
        obj.explore = "explore";
        obj.id = data.slug;
        obj.image = data.profilePhoto ? data.profilePhoto &&
            data.profilePhoto.thumbnail_path : logo;
        obj.venueName = data.name;
        obj.venueAddress = data.address &&
            data.address.city + ", " + stateAbbr;
        obj.favourite = " ";
        obj.category = type;
        featureVenueData.push(obj);
        return featureVenueData;
    })
    return (
        <div className="">
            <div class="container explore-ui-wrapper">
                <Helmet>
                    <title>Find a unique event space near me | FIRST-HOLD</title>
                    <meta name="description" content="Looking for a unique event space? We offer a variety of spaces and flexible packages to suit your events. Find your venue now!"></meta>
                    <meta name="robots" content="index"></meta>
                </Helmet>
                <Grid container className="topspace">                                        
                    <Grid className="banner-wrapper" item xs={12} sm={12} md={7} lg={7} xl={7}>
                        <div className="search-wrapper">
                            <div className="heading-text main">
                                <h1>Find a unique event space near me</h1>
                                <p>Discover unique spaces for your next meeting, event, or wedding.</p>
                            </div>
                            <div className="search-btn">
                                <a href="/browseVenue?address=San Francisco Bay Area" className="">Search</a>
                            </div>
                        </div>
                    </Grid>
                    <Grid className="" item xs={12} sm={12} md={5} lg={5} xl={5}>
                        <div className="text-center-image event-space-main">
                            {/* <img className="" src={require('../assets/images/Blog - Wedding - Photo7.jpg')} alt="FIRST-HOLD"/> */}
                            <a href="https://www.arrowoodphotography.com/"  target="_blank">Arrowood Photography</a>
                        </div>
                    </Grid>
                </Grid>
                {/* new block start */}
                <Grid container className="box-wrapper">
                    <Grid className="" item xs={12} sm={12} md={5} lg={5} xl={5}>
                        <div className="text-center-image event-space-main-1 left-side">
                            {/* <img className="" src={require('../assets/images/Blog - Wedding - Photo2.jpg')} alt="FIRST-HOLD"/> */}
                            <a href="https://www.arrowoodphotography.com/"  target="_blank">Arrowood Photography</a>
                        </div>
                    </Grid>
                    <Grid className="" item xs={12} sm={12} md={7} lg={7} xl={7}>
                        <div className="search-wrapper-block">
                            <div className="heading-text ">
                                <h1>How event spaces work on FIRST-HOLD</h1>
                                <p>FIRST-HOLD matches people planning events to venues with available dates --that's it.</p>
                            </div>
                            <div className="search-field margin-top">
                                <h2>Search size, style and budget</h2>
                                <p>Search for available event venues with the right size, vibe, and budget.</p>
                            </div>
                            <div className="search-field margin-top">
                                <h2>Immediately check availability</h2>
                                <p>Finding a unique venue that fits?  Know if your preferred date is
                                    available — even when you’re searching late at night! Place a hold or
                                    contact the venue to learn more.</p>
                            </div>
                            <div className="search-field margin-top">
                                <h2>Communicate directly</h2>
                                <p>FIRST-HOLD connects you directly with venue managers. They call you
                                    to learn more about your event — all while knowing your date is currently available.</p>
                            </div>
                            <div className="search-btn started">
                                <a href="/browseVenue?address=San Francisco Bay Area" className="">Get started</a>
                            </div>
                        </div>
                    </Grid>
                </Grid>
                <div className="featured-venue-bay box-wrapper">
                    <div className="heading-text">
                        <h1>Featured unique event spaces</h1>
                    </div>
                    <Grid container className="margin-10">
                        <Grid className="" item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <div className="search-wrapper-block two-box">
                                <div className="">
                                    <p>When you search for Bay Area venues on FIRST-HOLD, you  can search for many different characteristics that are important to your big event. </p>
                                    <p className="margin-top-5">Our meeting and event planners love to search by: </p>
                                    <ul>
                                        <li>event date</li>
                                        <li># of guests</li>
                                        <li>seating style (sit-down dinner or reception)</li>
                                        <li>venue rental budget</li>
                                    </ul>
                                </div>
                                <div className="search-field margin-top">
                                    <p><span className="bold">When you find a great venue, here’s the difference:</span>you’ll
                                        know immediately whether your preferred date is available
                                        (even when you’re searching late at night). </p>
                                </div>
                                <div className="search-field margin-top">
                                    <p>
                                        Also, your inquiries go directly to the venue manager and
                                        you’ll both get each other’s contact info. Both you and the
                                        venue communicate directly and avoid in-app chats.
                                    </p>
                                </div>
                                <div className="search-field margin-top">
                                    <p>All these features mean you’re <span className="bold">saving time in your day</span> and <span className="bold">reducing planning stress</span> when you use FIRST-HOLD
                                        to search for venues.</p>
                                </div>
                                <div className="search-btn started">
                                    <a href="/browseVenue?address=San Francisco Bay Area" className="">Check them out</a>
                                </div>
                            </div>
                        </Grid>
                        <Grid className="" item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <div className="text-center-image third-section event-space-main-2">
                                {/* <img className="" src={require('../assets/images/San Francisco - Bay Area1.jpg')} alt="FIRST-HOLD" /> */}
                                <a href="https://www.arrowoodphotography.com/"  target="_blank">Arrowood Photography</a>
                            </div>
                        </Grid>
                    </Grid>
                </div>
                <FeaturedVenues class="box-wrapper" featureVenueData={featureVenueData} />
                {/* Articles Section Start */}
                <div className="articles-section">
                    <div className="heading-text">
                        <h1>Articles</h1>
                    </div>
                    <div className="text-with-btn">
                        <Grid container>
                            <Grid item xs={12} sm={12} md={9} lg={6} xl={6}>
                                <h1>Why pick a unique and unusual event venue for your corporate event</h1>
                            </Grid>
                            <Grid item xs={12} sm={12} md={3} lg={6} xl={6} className="btn-right">
                                <a href="https://first-hold.squarespace.com/firsthold/why-pick-a-unique-and-unusual-venue-for-your-corporate-event" className="text-right article-btn">Read Article</a>
                            </Grid>
                        </Grid>
                    </div>
                    <p class="slider-heading-box frequently box-wrapper">
                        Are you excited for your next <span class="bold">corporate event</span> in a hotel ballroom? We didn’t think so. But what if your
                        upcoming conference was at a winery? Or at an art gallery? Or if it had a stunning view? If your
                        attendees get invited to events all the time, or attend the same events each year, use a fresh, interesting
                        <span class="bold"> corporate event venue</span> to draw their attention and attendance.
                    </p>
                    <div className="text-with-btn">
                        <Grid container>
                            <Grid item xs={12} sm={12} md={9} lg={6} xl={6}>
                                <h1>8 deal breakers when choosing your event venue</h1>
                            </Grid>
                            <Grid item xs={12} sm={12} md={3} lg={6} xl={6} className="btn-right">
                                <a href="https://first-hold.squarespace.com/firsthold/8-deal-breakers-to-consider-when-choosing-your-event-venue" className="text-right article-btn">Read Article</a>
                            </Grid>
                        </Grid>
                    </div>
                    <p class="slider-heading-box frequently box-wrapper">
                        Choosing an <span class="bold">event venue</span> for your next corporate meeting or event is the most important part of your
                        planning process. If you don’t have a venue, your event can’t happen. If you don’t have the right venue,
                        your event won’t achieve the experience and results you want it to. The Dealbreaker Checklist we’ve
                        created below will help you consider and identify essential criteria as you plan your next event.
                    </p>
                    <div className="text-with-btn">
                        <Grid container>
                            <Grid item xs={12} sm={12} md={9} lg={6} xl={6}>
                                <h1>What to look for in a professional meeting venue</h1>
                            </Grid>
                            <Grid item xs={12} sm={12} md={3} lg={6} xl={6} className="btn-right">
                                <a href="https://first-hold.squarespace.com/firsthold/what-to-look-for-in-a-professional-meeting-venue" className="text-right article-btn">Read Article</a>
                            </Grid>
                        </Grid>
                    </div>
                    <p class="slider-heading-box frequently box-wrapper">
                        There’s nothing worse than feeling unprepared for your in-person meeting with your CEOs, clients, or
                        funders. If you’ve decided to meet in-person, these meetings carry importance and require a level of
                        professionalism that only a professional <span class="bold">meeting venue</span> can provide. Here are 7 things to look for in your
                        next all star <span class="bold">meeting venue</span>.
                    </p>
                </div>
                {/* Articles Section End */}
                {/* Need More section start */}
                <div className="need-more-section box-wrapper">
                    <div className="need-more-wrapper">
                        <Grid container>
                            <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                                <div className="heading-text">
                                    <h1>Need more tips on finding the perfect venue?</h1>
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                <div className="search-btn">
                                    <a href="/blog" className="">Read more on our blog</a>
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                </div>
                <Footer />
            </div>
            <Footers />
        </div>
    );
}
eventspace.propTypes = {
    classes: object.isRequired,
    homepage: func.isRequired
};
const mapStateToProps = state => {
    return {

    };
};
const enhance = compose(
    withRouter,
    connect(mapStateToProps, dispatch =>
        bindActionCreators(
            {

                homepage

            },
            dispatch
        )
    )
);
export default enhance(withRouter(eventspace));
