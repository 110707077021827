import React from 'react';
import { connect } from "react-redux";
import { bindActionCreators, compose } from "redux";
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { googleSignUp } from "../../shared/googleSignUp/actions";
import './assets/style/ModalBox.scss';


const styles = theme => ({
  root: {
    margin: 0,
  },
  closeButton: {
    position: 'absolute',
    top: '8px',
    color: '#9e9e9e',
    right: '8px',
  },
});

withStyles(styles)(props => {
  const { children, classes, onClose } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
  },
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
  root: {
  },
}))(MuiDialogActions);

class ModalCalPermDen extends React.Component {
  state = {
    open: this.props.check,
  };
  componentWillMount() {
  
}
componentWillReceiveProps(nextProps){
this.setState({open:nextProps.check})
}
  handleClickOpen = () => {
    this.setState({
      open: true,
    });
  };

  handleClose = () => {
    this.setState({ open: false });
  };
redirect = () =>{
  console.log(window.location.href);
  localStorage.setItem("redirectUrl",window.location.href);
   this.props.googleSignUp().then((response) => {
             /*var link = document.getElementById('saveExit');
             link.click();  */  
             window.location.assign(response.googleUrlDetails.data.auth_url)
            })
    }
  render() {

    return (
      <div>
        {/* <Button variant="outlined" color="secondary" onClick={this.handleClickOpen}>
          Open dialog
        </Button> */}
        <Dialog className="success_modal_container"
          aria-labelledby="customized-dialog-title"
          open={this.state.open} >

          <DialogContent className="dialog-content">
            <Grid container className="modalBox_content">
       
              <Grid item xs={12}>
                <Grid container className="boxMatter" style={{padding:0}}>
                  <Grid item xs={12}>&nbsp;</Grid>
                  <Grid item xs={12}>
                    <div className="dont_single">Oops… Access Denied!</div>
                  </Grid>
                  <Grid item xs={12}>&nbsp;</Grid>
                  <Grid item md={12} sm={12}>
                    <div style={{textAlign:'center'}} className="dont_p_text dont_p-d-none-sm download-ext-text">Looks like we need a little permission magic! </div>
                    <div style={{textAlign:'center',margin:'10px 0'}} className="dont_p_text dont_p-d-none-sm download-ext-text">Please grant us full access to your calendar when logging in with Google, so we can keep everything running smoothly. </div>
                    {/* <div style={{textAlign:'center'}} className="dont_p_text dont_p-d-none-sm download-ext-text">Please approve all calendar permissions while logging with Google. </div> */}
                    {/* <div className="dont_p dont_p-d-none-lg">Manage your holds and see notifications in your browser window!  Download our Extension from your computer.  It’s Fast, Easy and FREE</div> */}
                  </Grid>

                  <Grid item xs={12} md={12} sm={12} className="">
                    <DialogActions className="action-center">
                      <Button onClick={()=>this.redirect()} style={{marginTop:'12px'}} className="button-bg dont_p-d-none-sm download-ext-btn">
                        Okay, Let’s Do It!
                          </Button>
                          {/* <Button style={{display:'none'}} onClick={()=>this.handleClose()} className="button-bg ok-class-show">
                            Ok
                          </Button>
                          <div className="cancel_button">
                          <DialogActions className="dialogActions">
                                 <span style={{display:'block'}} className="material-icons close closeButton" onClick={this.handleClose}>close</span>
                         </DialogActions>
                        </div>             */}
                                </DialogActions>
                  </Grid>
                  {/* <Grid item xs={12} md={12} sm={12}>
                    <span className="noThank_btn" onClick={this.handleClose} >Cancel</span>
                  </Grid> */}
                </Grid>
              </Grid>

              <Grid item xs={12}>&nbsp;</Grid>

            </Grid>

          </DialogContent>



        </Dialog>


      </div>
    );
  }
}
const mapStateToProps = state => {
    return {};
  };
  
// export default ModalCalPermDen;
const enhance = compose(
    connect(mapStateToProps, dispatch  =>
      bindActionCreators(
        {
          googleSignUp,
        },
        dispatch
      )
    )
  );
  export default enhance(ModalCalPermDen);