import React from 'react';
import PropTypes from 'prop-types';

import CustomInput from '../../shared/components/CustomInput';
import CustomizedButton from '../../shared/components/customizedButton';
import CustomSelectWithScroll from '../../shared/components/CustomSelectWithScroll';

//import {Formik} from 'formik';
import {Grid, Hidden} from '@material-ui/core';
import {withStyles} from '@material-ui/core/styles';
import { form ,useField,Formik,Field, ErrorMessage, useFormikContext,useFormik } from 'formik';
import { useRef, useEffect,useState} from "react";
//import Autocomplete from 'react-google-autocomplete';
import { googleKeyData } from "shared/googleKeyData/actions";
import { object, func } from "prop-types";
import { bindActionCreators} from "redux";
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from "react-router-dom";
let autoComplete;

let streetNumber,address_line1='' ,streetName ,sublocality_level_1, cityName ,postCode ,stateName,city_enable=true,postcode_enable=true,addressline1_enable=true,addressline2_enable=true

const loadScript = (url, callback) => {
  let script = document.createElement("script");
  script.type = "text/javascript";
  if (script.readyState) {
    script.onreadystatechange = function() {
      if (script.readyState === "loaded" || script.readyState === "complete") {
        script.onreadystatechange = null;
        callback();
      }
    };
  } else {
    script.onload = () => callback();
  }

  script.src = url;
  document.getElementsByTagName("head")[0].appendChild(script);
};
function handleScriptLoad(updateQuery, setFieldValue, autoCompleteRef,props) {
  autoComplete = new window.google.maps.places.Autocomplete(
    autoCompleteRef.current,
    {  //fields: ["address_component"],
      types: ["address"], 
      // componentRestrictions: { country: "us" } 
    }
  );
  autoComplete.setFields(["address_components", "formatted_address"]);
  autoComplete.addListener("place_changed", () =>
    handlePlaceSelect(updateQuery,setFieldValue,props)
    
    
  );
}
async function handlePlaceSelect(updateQuery,setFieldValue,props) {
  const addressObject = autoComplete.getPlace();
  const query = addressObject.address_components;
  updateQuery(addressObject)
 // setFieldValue('line_1',address_line1)
//  document.getElementsByName("addressLine1")[0].innerHTML = address_line1;
 setFieldValue.addressLine1=address_line1
  // setFieldValue('line_2',sublocality_level_1)
  // setFieldValue('city',cityName)
  // setFieldValue('state',stateName)
  // setFieldValue('zipcode',postCode)
  //setFieldValue('job_title','') 
  //handleChangeCompanyName(setFieldValue);
  props.handleChangeValue(address_line1,sublocality_level_1,cityName,stateName,postCode)

}
const styles = theme => ({
    icon: {
        marginLeft: '8px',
        paddingTop: '1px',
        color: '#b0b0b0',
        fontSize: '15px'
    },
    activeIcon: {
        marginLeft: '8px',
        paddingTop: '1px',
        color: '#1397e1',
        fontSize: '15px'
    },
    popper: {
        paddingLeft: '15px',
        paddingTop: '237px'
    },
    root: {
        boxShadow: '0px 4px 11px 4px rgba(23,0,19,0.14)',
        borderRadius: '6px'
    },
    typography: {
        width: '237px',
        height: '120px',
        fontSize: '14px',
        fontWeight: '500',
        fontStyle: 'normal',
        fontStretch: 'normal',
        lineHeight: '1.43',
        letterSpacing: 'normal',
        padding: '19px',
        color: '#1397e1',
        fontFamily: 'Raleway'
    }
});

function CompanyInfo(props) {
    // const { setFieldValue, handleChange, values, handleBlur } = useFormikContext();

    const [query, setQuery] = useState("");
    const autoCompleteRef = useRef(null);
    useEffect(() => {
      props.googleKeyData().then((result) => {
              loadScript(`https://maps.googleapis.com/maps/api/js?key=${result.googleData.google_map_key}&libraries=geometry,drawing,places`,
        () => handleScriptLoad(setQuery,props.initialValues, autoCompleteRef,props)                                     
        );
    })
      }, []);
    const autoCompleteRef1 = useRef(null);
    useEffect(() => {
      props.googleKeyData().then((result) => {
              loadScript(`https://maps.googleapis.com/maps/api/js?key=${result.googleData.google_map_key}&libraries=geometry,drawing,places`,
        () => handleScriptLoad(setQuery,props.initialValues, autoCompleteRef1,props)                                     
        );
    })
      }, []);
   if(query.address_components)
   
   {
    streetNumber=''
    streetName=''
    cityName=''
    postCode=''
    sublocality_level_1=''
    city_enable=true
    postcode_enable=true
    addressline1_enable=true
    addressline2_enable=true
    query.address_components.forEach(component => {
      let { long_name, types } = component
      if (types.includes('street_number')) {
        streetNumber = long_name
      } 
      else if (types.includes('route')) {
        streetName = long_name
      } 
      else if (types.includes('administrative_area_level_1')) {
        stateName= long_name
      }
      else if (types.includes('locality')) {
        cityName = long_name
      } 
      else if (types.includes('sublocality_level_1')||types.includes('neighborhood') ){
        sublocality_level_1 = long_name
      } 
      
      else if (types.includes('postal_code')) {
        postCode = long_name
      }  address_line1=''
      address_line1=(streetNumber?streetNumber:'')+ ' '+(streetName?streetName:'')
   })
   if(cityName=='')
   {
   city_enable=false
   }
   if(postCode=='')
   {
   postcode_enable=false
   }
   if(sublocality_level_1=='')
   {
    addressline2_enable=false
   }
   if(address_line1=='')
   {
    addressline1_enable=false
   }
  }
//   const { setFieldValue, handleChange, values, handleBlur } = useFormikContext();
 
let states=[]
let key=[]
// const tier = localStorage.getItem('tier')
props.states !== [] && props.states.map(function (data, idx) {

  states.push(data.name)
  key.push(idx)
  return key;
});
    const {userType} = props;
    return (
        <div>
            <Grid container className="edit_profile_component">
                <Grid className="headings" item xs={12}>
                    <h4 className="form_name">Company Info</h4>
                </Grid>
                <Grid item xs={12}>
                    <Formik
                        initialValues={props.initialValues}
                        enableReinitialize
                        validationSchema={props.validationSchema}
                        onSubmit={(values, actions) => {
                        props.handleSubmit(values, actions)
                    }}
                        render={props => (
                        <form onSubmit={props.handleSubmit}>
                            <Grid container>
                                <Hidden xsDown={true}>
                                    <Grid item sm={2} md={2} lg={2} xl={2}></Grid>
                                </Hidden>
                                <Grid item xs={12} sm={8} md={8} lg={8} xl={8}>
                                {/* <Grid className="left_panel" item xs={12} sm={12} md={6} lg={6} xl={6}>
                                            <div className="field_div search_container">
                                            <div 
                                                style={{
                                                width: '60px !important',
                                                height: '46px !important',
                                                position: 'absolute',
                                                top: '0px'}}></div>
                                                  <input
                                                    ref={autoCompleteRef}
                                                    className="emailField" align="center" type="text" name="location"  onChange={e=>{setQuery(e.target.value) 
                                                    }
                                                    }
                                                    placeholder="Search Address"
                                                    
                                                    />
                                            </div>
                                        </Grid> */}
                                    { parseInt(userType) !== 1&& 
                                    <Grid container className="edit_profile_component_form">                                        
                                             <Grid className="left_panel" item xs={12} sm={12} md={6} lg={6} xl={6}>
                                                    <div className="field_div">
                                                        <CustomInput
                                                            label="Company Name"
                                                            type="text"
                                                            name="companyName"
                                                            placeholder="Enter Company Name"/>
                                                    </div>
                                                </Grid>
                                                 <Grid className="" item xs={12} sm={12} md={6} lg={6} xl={6}>
                                            <div className="field_div custom_input search_container with-lable-search">
                                            <div 
                                                style={{
                                                width: '60px !important',
                                                height: '46px !important',
                                                position: 'absolute',
                                                top: '0px'}}></div>
                                                <label className='emailLabel sm-pd-top'>Address</label>
                                                <input type="text" style={{ display: 'none' }} autoComplete="off" />
                                                  <input
                                                    autoComplete='off'
                                                    ref={autoCompleteRef}
                                                    className="emailField "
                                                    align="center" type="text" name="location"  onChange={e=>{setQuery(e.target.value) 
                                                    }
                                                    }
                                                    placeholder="Type here to search..."
                                                    
                                                    />
                                            </div>
                                        </Grid>
                                    </Grid>
                                    }
                                    { parseInt(userType) === 1 && 

                                        <Grid className="left_panel" item xs={12} sm={12} md={6} lg={6} xl={6}>
                                            <div className="field_div custom_input search_container with-lable-search">
                                            <div 
                                                style={{
                                                width: '60px !important',
                                                height: '46px !important',
                                                position: 'absolute',
                                                top: '0px'}}></div>
                                                <label className='emailLabel sm-pd-top'>Address</label>
                                                <input type="text" style={{ display: 'none' }} autoComplete="off" />
                                                  <input
                                                    autoComplete='off'
                                                    ref={autoCompleteRef1}
                                                    className="emailField" align="center" type="text" name="location"  onChange={e=>{setQuery(e.target.value) 
                                                    }
                                                    }
                                                    placeholder="Type here to search..."
                                                    
                                                    />
                                            </div>
                                        </Grid>
                                    }
                                    <Grid container className="edit_profile_component_form">
                                        <Grid className="left_panel" item xs={12} sm={12} md={6} lg={6} xl={6}>
                                            <div className="field_div">
                                                <CustomInput
                                                    label="Address Line 1"
                                                    type="text"
                                                    name="addressLine1"
                                                    placeholder="Address Line 1"
                                                    disabled={addressline1_enable}/>
                                            </div>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                            <div className="field_div">
                                                <CustomInput
                                                    label="Address Line 2"
                                                    type="text"
                                                    name="addressLine2"
                                                    placeholder="Address Line 2"
                                                    disabled={addressline2_enable}/>
                                            </div>
                                        </Grid>
                                        <Grid className="left_panel" item xs={12} sm={12} md={6} lg={6} xl={6}>
                                            <div className="field_div">
                                                <CustomInput label="City" type="text" name="city" placeholder="City" disabled={city_enable}/>
                                            </div>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                            <div className="field_div">
                                                <CustomSelectWithScroll
                                                    placeholder="Select"
                                                    states={states}
                                                    name="state"
                                                    label="State / Province"
                                                    value={props.values.state}
                                                    handleChange={props.handleChange}
                                                    handleBlur={props.handleBlur}
                                                    registrationSelect
                                                    showLabel
                                                    disabled={true}/>
                                            </div>
                                        </Grid>
                                        <Grid className="left_panel" item xs={12} sm={12} md={6} lg={6} xl={6}>
                                            <div className="field_div">
                                                <CustomInput
                                                    label="Zip Code"
                                                    type="text"
                                                    name="zipCode"
                                                    placeholder="Zip Code"
                                                    disabled={postcode_enable}/>
                                            </div>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                            <div className="field_div">
                                                <CustomInput
                                                    label="Job Title"
                                                    type="text"
                                                    name="jobTitle"
                                                    placeholder="Job Title"/>
                                            </div>
                                        </Grid>
                                    </Grid>
                                </ Grid>
                                <Grid className="save_button" item xs={12}>
                                    <Grid container justify='flex-end'>
                                        <Grid item>
                                            <CustomizedButton label="Save" type="submit"/>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Hidden xsDown={true}>
                                    <Grid item sm={2} md={2} lg={2} xl={2}></Grid>
                                </Hidden>
                            </Grid>
                        </form>
                    )}/>
                </Grid>
            </Grid>
        </div>
    );
}

// CompanyInfo.propTypes = {
//     classes: PropTypes.object.isRequired
// };

// const enhance = withStyles(styles)

// export default enhance(CompanyInfo);
const mapStateToProps = state => {
    return {
      states: state.data.auth.states,
      googleKeyDetails: state.data.googleKeyData.googleKey,
      
    };
  };
  
  CompanyInfo.propTypes = {
    classes: PropTypes.object.isRequired,
    googleKeyData: func.isRequired,
  };
  
  
  const enhance = compose(
    withStyles(styles),
    connect(
      mapStateToProps,
    null),
    connect(mapStateToProps, dispatch =>
      bindActionCreators(
        {
        googleKeyData
        },
        dispatch
      )
    )
  )
  export default enhance(withRouter(CompanyInfo));
